<template>
  <div class="coupon">
    <div class="header">
      <div class="left" @click="goback">
        <van-icon name="arrow-left" class="icon" />
      </div>
      <div class="title">优惠券</div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        :class="item.isOverdue == 1 || item.isUse == 0 ? 'overdue' : ''"
      >
        <div class="left">
          <div class="price">¥{{ item.price }}</div>
          <div class="name">新人注册</div>
        </div>
        <div class="right">
          <div class="top">
            <div class="top_left">
              <div class="title">{{ item.courseCouponName }}</div>
              <div class="validity">
                <span>有限期:</span>
                <span v-if="item.effectiveBtime"
                  >{{ item.effectiveBtime }} - {{ item.effectiveEtime }}</span
                >
                <span v-else>无期限</span>
              </div>
            </div>
            <div
              class="top_right"
              @click="item.isOverdue == 0 ? use(item) : null"
            >
              {{ item.isOverdue == 0 ? "去使用" : "已过期" }}
            </div>
          </div>
          <div class="bottom">
            <div class="purposeList">
              <div
                class="purPoseItem"
                v-for="(purPoseItem, ind) in item.availableCoursePackageList"
                :key="ind"
              >
                {{ purPoseItem }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { couponlist } from "@/api/order.js";
export default {
  data() {
    return {
      list: [
        // {
        //   title: "两科以上优惠专享",
        //   effectiveBtime: "2021.09.22",
        //   effectiveEtime: "2021.10.31",
        //   courseCouponName: "新人专用",
        //   availableCoursePackageList: [
        //     "限商品单价，所有优惠金额满640元使用",
        //     "仅限初级会计学员课程使用",
        //     "仅限初级会计学员课程使用",
        //   ],
        //   price: "500",
        //   isOverdue: 0,
        // },
        // {
        //   title: "两科以上优惠专享",
        //   effectiveBtime: "2021.09.22",
        //   effectiveEtime: "2021.10.31",
        //   courseCouponName: "新人专用",
        //   availableCoursePackageList: [
        //     "限商品单价，所有优惠金额满640元使用",
        //     "仅限初级会计学员课程使用",
        //     "仅限初级会计学员课程使用",
        //   ],
        //   price: "500",
        //   isOverdue: 1,
        // },
      ],
    };
  },
  created() {
    this.getlist();
  },
  name: "message",
  methods: {
    goback() {
      this.$router.go(-1);
    },
    getlist() {
      let data = {};
      if (this.$route.query.coursePackageId) {
        data.coursePackageId = this.$route.query.coursePackageId;
      }
      couponlist(data).then((res) => {
        if (res.data.code == 0) {
          console.log(res);
          this.list = res.data.data;
        }
      });
    },
    // 使用
    use(item) {
      if(item.isUse == 0) {
        return
      }
      window.sessionStorage.setItem("couponObj", JSON.stringify(item));
      window.sessionStorage.setItem("couponFlag", "true");
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 10px 10px;
  display: flex;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 550;
    }
  }
  .title {
    width: 80%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
}
.list {
  padding: 17px 14px;
  background: #f8f8f8;
  min-height: 88vh;
  .item {
    display: flex;
    border-radius: 9px; /*no*/
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
    .left {
      width: 30%;
      background: #5d7dff;
      color: #fff;
      text-align: center;
      padding-top: 24px;
      .price {
        width: 100%;
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #feffff;
      }
      .name {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #feffff;
        line-height: 15px;
        margin-top: 14px;
      }
    }
    .right {
      flex: 1;
      background: #fff;
      padding: 17px 7px 17px 12px;
      .top {
        display: flex;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px dashed #979797;
        .top_left {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 550;
          color: #141414;
          width: 80%;
        }
        .top_right {
          background: #eaeeff;
          color: #5d7dff;
          border-radius: 5px;
          height: 22px;
          font-size: 11px;
          line-height: 22px;
          padding: 4px 5px;
        }
        .validity {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 5px;
        }
      }
      .bottom {
        .purPoseItem {
          margin-bottom: 3px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .overdue {
    .left {
      background: #dadbe0;
    }
    .top_right {
      background: #f7f7f7 !important;
      color: #b2b2b2 !important;
    }
  }
}
</style>
